import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { IPost } from 'src/utils/ts/blog';
import Layout from 'src/components/common/Layout';
import { MetaData } from 'src/components/common/Meta';
import Button from 'src/components/common/Button';
import {
  HeroSection,
  ServiceSection,
  InfoSection,
  BlogSection,
  TestimonialSection,
} from 'src/components/HomeContent';

interface IIndex {
  data: {
    allGhostPost: {
      edges: {
        node: IPost;
      }[];
    };
  };
  location: {
    pathname: string;
  };
  pageContext: {
    isHome: boolean;
  };
}

const Index = ({
  location,
  pageContext,
  data,
}: IIndex): ReactElement => {
  const posts = data.allGhostPost.edges;
  return (
    <>
      <MetaData location={location} />
      <Layout isHome={pageContext.isHome}>
        <HeroSection data={homePageData.hero}>
          <Button type="button" to="/contact">
            Contact Us
          </Button>
        </HeroSection>
        <ServiceSection data={homePageData.services} />
        <InfoSection data={homePageData.info} />
        <BlogSection data={homePageData.blog} posts={posts} />
        <TestimonialSection data={homePageData.testimonials} />
      </Layout>
    </>
  );
};

const homePageData = {
  hero: {
    title: 'The Tech experience For All User',
    description:
      'Tools, tutorials, design and innovation experts, all inone place! The most intuitive way to imagine your next user experience.',
    image: '/images/skr-index.svg',
  },
  services: {
    title: 'Our Services',
    headline:
      'We builds amazing software products, for various versions of your platform.',
    items: [
      {
        title: 'Design',
        description:
          'We provide Innovative, Superior, High-Quality and Affordable Software Products that Businesses, Governments and Customers love',
        image: 'images/design.svg',
        link: 'design',
      },
      {
        title: 'Development',
        description:
          'We provide Innovative, Superior, High-Quality and Affordable Software Products that Businesses, Governments and Customers love',
        image: 'images/development.svg',
        link: 'development',
      },
      {
        title: 'Project Management',
        description:
          'We provide Innovative, Superior, High-Quality and Affordable Software Products that Businesses, Governments and Customers love',
        image: 'images/product_management.svg',
        link: 'project-managment',
      },
      {
        title: 'Branding',
        description:
          'We provide Innovative, Superior, High-Quality and Affordable Software Products that Businesses, Governments and Customers love',
        image: 'images/branding.svg',
        link: 'branding',
      },
    ],
  },
  info: [
    {
      title: 'Effortless Validation for',
      headline: 'Digital And Cloud Transformation',
      image: 'images/group-30.svg',
      backgroundImage: 'images/background-1.svg',
      type: 'right',
      items: [
        {
          title: 'Accessory makers',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          title: 'Alterationists',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          title: 'Custom Design designers',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
      ],
    },
    {
      title: 'Easier decision making for',
      headline: 'Our Software Service Consulting',
      image: 'images/group-31.svg',
      backgroundImage: 'images/background-2.svg',
      type: 'left',
      items: [
        {
          title: '',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          title: '',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          title: '',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
      ],
    },
    {
      title: 'Optimisation for',
      headline: 'Training Platform Reselling',
      image: 'images/group-12.svg',
      backgroundImage: '',
      type: 'right',
      items: [
        {
          title: 'Accessory makers',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          title: 'Alterationists',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          title: 'Custom Design designers',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
      ],
    },
  ],
  blog: {
    title: 'Our Blog',
    headline:
      'We builds amazing software products, for various versions of your platform.',
    backgroundImage: 'images/background-1.svg',
  },
  testimonials: {
    title: 'Testimonials',
    headline:
      'We builds amazing software products, for various versions of your platform.',
    items: [
      {
        name: 'test',
        image: '/images/tst1.png',
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
      {
        name: 'test',
        image: '/images/tst2.png',
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
      {
        name: 'test',
        image: '/images/tst3.png',
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
      {
        name: 'test',
        image: '/images/tst1.png',
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
      {
        name: 'test',
        image: '/images/tst2.png',
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
      {
        name: 'test',
        image: '/images/tst3.png',
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
      {
        name: 'test',
        image: '/images/tst3.png',
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
      {
        name: 'test',
        image: '/images/tst2.png',
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
      {
        name: 'test',
        image: '/images/tst1.png',
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
    ],
  },
};

export default Index;

export const indexQuery = graphql`
  query GhostPostHomeQuery {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: 9
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
